import React, { useState, useEffect } from 'react';
import useUser from '../../hooks/useUser';
import { playerListUnsold,addWishList } from '../../redux/slice/playerSlice';
import { myTeam } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';

const MyTeam = () => {
  const token = useUser();
  var imageUrl = serverUrl.imageUrlProfile;
  var imageUrlTeam = serverUrl.imageUrlTeam;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [team, setTeam] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filter, setFilter] = useState('All');

  useEffect(() => {
    handlePlayer();
     const fetchDataTeam = async () => {
      try {
        var datapost = {
          team_id:token.teamId
        } ;
        const jsonData = await dispatch(myTeam(datapost));
        setTeam(jsonData.payload);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchDataTeam();
  }, []);

  const handlePlayer = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:token.teamId
          } ;
          const jsonData = await dispatch(playerListUnsold(datapost));
          const filteredPlayer = jsonData.payload.filter(obj => {
            if(filter === 'Male'){
              return obj.gender === 'M';
            }else if(filter === 'Female'){
              return obj.gender === 'F';
            }else if(filter === 'Singles'){
              return obj.filter === filter;
            }else if(filter === 'Doubles'){
              return obj.speciality === filter;
            }else if(filter === 'Indian'){
              return obj.nationality === 'India';
            }else{
              return obj;
            }
          });
          setData(jsonData.payload);
          setFilteredPlayers(filteredPlayer);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

    const handleNavigate = (player) => {
      const queryParams = new URLSearchParams({
        player: player,
      }).toString();
      window.open('/player-detail?'+queryParams, '_blank');
    };

    const handleFilter = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        if (selectedFilter === 'All') {
            setFilteredPlayers(data); // Reset to all players
        } else {
           const filteredPlayer = data.filter(obj => {
            if(selectedFilter === 'Male'){
              return obj.gender === 'M';
            }else if(selectedFilter === 'Female'){
              return obj.gender === 'F';
            }else if(selectedFilter === 'Singles'){
              return obj.speciality === selectedFilter;
            }else if(selectedFilter === 'Doubles'){
              return obj.speciality === selectedFilter;
            }else if(selectedFilter === 'Indian'){
              return obj.nationality === 'India';
            }else{
              return obj;
            }
          });
         setFilteredPlayers(filteredPlayer);
        }
        
    };

     const handleAddWish = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:token.teamId,
            player_id:player,
          } ;
          const jsonData = await dispatch(addWishList(datapost));
          handlePlayer();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    };

    const heart = require('../../assets/images/heart.png');
    const heartFill = require('../../assets/images/heartFilled.png');
    const male_profile = require('../../assets/images/male_profile.png');
    const female_profile = require('../../assets/images/female_profile.png');
    const logo = require('../../assets/images/wpbl-logo.webp');
    const profileIcon = require('../../assets/images/icons8-profile-picture-100.png');
    return (
      <div style={{width: '100%', height: '100%', position: 'relative', background: '#F8F9FA'}}>
          {team !== null ?
            <>
            <div style={{width:'100%',display:'flex', margin:'10px', justifyContent:'center', flexDirection:'row', position:'relative', top:40, zIndex:'0', height:'420px'}}>
              <div style={{width:'20%', height:'100%', display:'flex', flexDirection:'column', postion:'relative', gap:30, border:'2px solid',borderRadius:'10px',zIndex:'1', backgroundColor:'azure'}}>
                <img style={{ width: '50%', height: 180, left:'25%', position: 'relative' }} src={team.teamLogo !== null ? imageUrlTeam+team.teamLogo : logo} />
                <div style={{left:'5%', position: 'relative', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '500', wordWrap: 'break-word' }}>Team   : {team.name}</div>
                <div style={{ left:'5%', position: 'relative', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '500', wordWrap: 'break-word' }}>City      : {team.city}</div>
                 <div style={{left:'5%',position: 'relative', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '500', wordWrap: 'break-word' }}>Coach : {team.coach}</div>
              </div>
              <div style={{width:'78%', display:'flex', position:'relative',zIndex:'1'}}>
                        <div style={{width:'100%',position:'relative', display: 'grid', gridTemplateColumns:'repeat(4, 1fr)'}}>
                            {team.playerData?.length > 0 ?
                              team.playerData.map((player,indexData) => (
                             <span key={indexData}>
                              {player.player_id !== 0 ?
                              <span>   
                              <div style={{width:'100%', height:'210px', display:'flex', flexDirection:'column', postion:'relative', border:'2px solid #464255', borderRadius:'10px', gap:'10px'}}>
                                <div style={{width:'93%', display:'flex', flexDirection:'row', postion:'relative', margin:10,margin:10, gap:'8%'}}>
                                   <img style={{ width: '20%', height: 30,position: 'relative' }} src={player.nationality !=='' ? require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`) : '' } />
                                    <img style={{ width: '45%', height: 125, position: 'relative', borderRadius: 100, objectFit:'cover'}} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : profileIcon} />
                                    <div style={{position: 'relative', color: '#464255', fontSize: 30, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{player.gender}</div>
                                </div>
                                <div style={{width:'100%', display:'flex', flexDirection:'row', marginLeft:'5px', postion:'relative', gap:'5px'}}>
                                 <div style={{width:'80%', display:'flex', flexDirection:'column', postion:'relative'}}>
                                  <div style={{position: 'relative', color: '#464255', fontSize: 20, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '600', wordWrap: 'break-word' }}>{player.player_name}</div>
                                  <div style={{ width: '60%', height: 30,position: 'relative', fontSize:'16px' }} src="https://via.placeholder.com/30x30" >{player.speciality}, {player.age}</div>
                                 </div>
                                 <div style={{width:'50%', display:'flex', flexDirection:'column', position:'relative', gap:'6px', top:'5px'}}>
                                    <div style={{position: 'relative', color: '#464255',textAlign:'center', fontSize: 17, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>DRS:{player.duprRatingSingles}</div>
                                    <div style={{position: 'relative', color: '#464255',textAlign:'center', fontSize: 17, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>DRD:{player.duprRatingDoubles}</div>
                                 </div>
                                </div>
                                  
                              </div>
                              </span>
                              :
                              <span>
                              <div style={{display:'flex', flexDirection:'column', height:'190px', alignItems:'center', border:'2px solid #464255', borderRadius:'10px', height:'210px', justifyContent:'center'}}>
                                <img style={{ width: 100, height: 100, position: 'relative' }} src={profileIcon} />
                                <div style={{ position: 'relative', opacity: 0.70, color: '#464255', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>(To be Selected)</div>
                                </div>
                              </span>
                              }
                              </span>
                             ))
                             :""}
                            {/*<div style={{width:'100%', display:'flex', flexDirection:'row', postion:'relative'}}>
                            </div>
                            <div style={{width:'100%', display:'flex', flexDirection:'row', postion:'relative'}}>
                                <div style={{width:'50%', display:'flex', flexDirection:'column', postion:'relative'}}>
                                </div>
                                <div style={{width:'50%', display:'flex', flexDirection:'column', postion:'relative'}}>
                                </div>
                            </div>*/}
                        </div>
                        
              </div>
             
            </div>
           
            
            
            {/*{team.playerData?.length > 0 ?
              team.playerData.map((player,indexData) => (
             <span key={indexData}>
              {player.player_id !== 0 ?
              <span>   
                  <div style={{ width: 120, height: 120, left: 539, top: 197, position: 'absolute', borderRadius: 120 }} />
                  <img style={{ width: 110, height: 110, left: 544, top: 202, position: 'absolute', borderRadius: 100 }} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : profileIcon} />
                  <div style={{ left: 438, top: 337, position: 'absolute', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '500', wordWrap: 'break-word' }}>{player.player_name}</div>
                  <div style={{ width: 30, height: 30, left: 438, top: 371, position: 'absolute' }} src="https://via.placeholder.com/30x30" >{player.speciality}</div>
                  <div style={{ width: 22.02, height: 0, left: 501.50, top: 397.01, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '2px #464255 solid' }}></div>
                  <div style={{left: 741, top: 190, position: 'absolute', color: '#464255', fontSize: 30, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{player.gender}</div>
                  <div style={{left: 516, top: 372, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 20, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{player.age}</div>
                  <img style={{ width: 40, height: 20, left: 446, top: 197, position: 'absolute' }} src={player.nationality !=='' ? require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`) : '' } />
                  <div style={{ width: 59.08, height: 0, left: 598.50, top: 401.04, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #464255 solid' }}></div>
                 
                  <div style={{ left: 625, top: 339, position: 'absolute', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>DRS:{player.duprRatingSingles}</div>
                  <div style={{ left: 625, top: 377, position: 'absolute', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>DRD:{player.duprRatingDoubles}</div>
                  
                  
              </span>
              :
              <span>
                <img style={{ width: 100, height: 100, left: 1607, top: 227, position: 'absolute' }} src={profileIcon} />
                <div style={{ left: 1592, top: 589, position: 'absolute', opacity: 0.70, color: '#464255', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>(To be Selected)</div>
                <div style={{ width: 1400.04, height: 0, left: 429.98, top: 432, position: 'absolute', border: '3px #464255 solid' }}></div>
              </span>
              }
              </span>
             ))
             :""}*/}
            <div style={{display:'flex', flexDirection: 'row', top:40, width:'100%', position:'relative'}}>
          <div style={{ width: '40%', top:'35px', left:'2%', height: 53,position: 'relative', display:'flex', flexDirection:'row', transform: 'skew(45deg)',background: '#29ABE2', borderRadius: '3px', transform: 'skew(45deg)' }}>  
            <div style={{ left: '5%', top: 13, position: 'relative', color: 'white', fontSize: 25, fontFamily: 'Roboto', fontStyle: 'italic', transform: 'skew(-45deg)', fontWeight: '500', wordWrap: 'break-word' }}>Players Count -</div>
            <div style={{ left: '10%', top: 13, position: 'relative', transform: 'skew(-45deg)'}}><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", wordWrap: "break-word"' }}>Male: </span><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", textDecoration: "underline", wordWrap: "break-word"' }}>{team?.malePlayer}</span></div>
            <div style={{ left: '18%', top: 13, position: 'relative', transform: 'skew(-45deg)' }}><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", wordWrap: "break-word"' }}>Female: </span><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", textDecoration: "underline", wordWrap: "break-word"' }}>{team?.femalePlayer}</span></div>
            <div style={{ left: '25%', top: 13, position: 'relative', transform: 'skew(-45deg)' }}><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", wordWrap: "break-word"' }}>Indian: </span><span style={{ color: '"white", fontSize: 25, fontFamily: "Roboto", fontStyle: "italic", fontWeight: "500", textDecoration: "underline", wordWrap: "break-word"' }}>{team?.indianPlayer}</span></div>
          </div>
          <img style={{left:'4%', width: '9%', height: 130, position: 'relative' }} src={logo} />

          <div style={{ width: '44%', height: 53, position: 'relative', background: '#29ABE2', borderRadius: 3, transform: 'skew(45deg)',top:'34px', left:'5%'}}>
           <div style={{ width: '50%', height: 32, left:'6%', top:'15%',position: 'relative', color: 'white', fontSize: 30, fontFamily: 'Roboto', fontStyle: 'italic', fontWeight: '500', wordWrap: 'break-word',transform: 'skew(-45deg)'  }}>Unsold Players</div>
           </div>
           </div>
          <div style={{ width:'100%',left: '2%', position: 'relative' }}>
          <div style={{width: '150px', height: '25px', left: '85%', top: '40px', position: 'relative'}}>
              <select name="fillter" value={filter}  className="fillterData" onChange={handleFilter}>
                <option value="">Select Filter</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Singles">Singles</option>
                <option value="Doubles">Doubles</option>
                <option value="Indian">Indian</option>
              </select>
            </div>
          <div style={{width: '95%',left: '4%', top: '80px', position: 'relative'}}>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', display: 'grid', gridTemplateColumns:'repeat(5, 1fr)'}}>
           
              {filteredPlayers.length > 0 ?
                filteredPlayers.map((player,index) => (
                <div style={{width: '100%', height: 190, position: 'relative'}} key={index} >
                    <div style={{width: '40%', height: 150, left: 0, top: 0, position: 'absolute', boxShadow: '4px 6px 4px rgba(0, 0, 0, 0.30)', borderRadius: 9999, transition: 'transform 0.3s ease', cursor: 'pointer'}}onMouseEnter={(e) => {
                          e.target.style.transform = 'scale(1.05)'; 
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.transform = 'scale(1)';
                        }}> 
                      <img style={{width: '95%', height: 140, left:'3%', top: 8, position: 'absolute', borderRadius: 110, transition: 'transform 0.3s ease', objectFit:'cover'}} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : (player.gender ==='F' ? female_profile : male_profile)}  onClick={()=>handleNavigate(player.id)} /> 
                    </div>
                   
                    <div style={{width: '50%',left: '44%', top: '10%', position: 'absolute', color: '#464255', fontSize: 20, fontFamily: 'Roboto', fontWeight: '800', wordWrap: 'break-word'}} >{player.firstname+' '+player.lastname}</div>
                    <div style={{width: '50%', height: 25, left: '44%', top: '35%', position: 'absolute'}}>
                    <div style={{left: 0, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 18, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.age}</div>
                    <div style={{width: 17, height: 0, left: 25, top: 20, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1.5px #464255 solid'}}></div>
                    <div style={{left: 31, width: 25, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 18, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.gender}</div>
                    <div style={{width: 17, height: 0, left: 60, top: 20, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1.5px #464255 solid'}}></div>
                    <div style={{left: 70, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 18, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.speciality}</div>
                    </div>
                    <img style={{width: 50, height: 30, left: '44%', top: 110, position: 'absolute', boxShadow: '4px 6px 4px rgba(0, 0, 0, 0.30)'}} src={require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`)} />

                    <img onClick={()=>handleAddWish(player.id)} style={{width: 40, height: 40, left: '75%', top: 105, position: 'absolute'}}  src={player.isInWishlist === true ? heartFill : heart} />
                </div>   
              ))
              :""}


              
            </div>
            
          </div>

        </div>
            </>
             :""}
           

    </div>
    );
};

export default MyTeam;