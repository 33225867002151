import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam } from '../../redux/slice/teamSlice';

const CreateTeam = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    var createData = {
        name:"Flexc Tiger",
        city:"Pune",
        owner:"Girish Kukreja",
        captain:"Vijay Mane",
        coach:"Ananth A",
        founded_year:2024
    };
    dispatch(createTeam(createData));
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    // dispatch(createUser(createData));
  };
  const blur = require('../../assets/images/blur.jpg');
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <div>
      
    </div>
  );
};

export default CreateTeam;
