import React, { useState, useEffect } from 'react';
import loginstyles from './login.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Or however you store your token

    if (token) {
      navigate('/player-list', { replace: true });
    }
  }, [navigate]);
   
  const handleSubmit = async  (e) => {
    e.preventDefault();
    const result = await dispatch(loginUser({ email, password }));
    if (result.meta.requestStatus === 'fulfilled') {
      navigate('/player-list'); // Redirect on successful login
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const blur = require('../../assets/images/blur.jpg');
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <form onSubmit={handleSubmit}>
     <div style={{ position: 'fixed', width: '100%', height: '100vh' }}>
      {/* Background Image */}
      <img
        className="login-bg"
        src={blur}
        alt="Background"
        style={{ width: '100%', height: '100%', position: 'absolute' }}
      />

      {/* Login Box */}
      <div
        style={{
          width: '400px',
          height: '550px',
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          background: '#FFFDFD',
          boxShadow: '10px 10px 10px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '20px',
        }}
      >
        {/* Profile Image */}
        <img
          src={logo}
          alt="Profile"
          style={{
            width: '180px',
            height: '180px',
            position: 'absolute',
            top: '16px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />

        {/* Labels */}
        <div
          style={{
            position: 'absolute',
            top: '200px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '21px',
            fontFamily: 'Roboto',
            fontWeight: '800',
          }}
        >
        <span style={{ fontWeight: '400' }}>LOGIN</span>
        </div>

        {/* Input Fields */}
          <input type="email" name="email" required="required" value={email}
            onChange={(e) => setEmail(e.target.value)} 
            className={loginstyles.loginInput} 
            placeholder="Email" 
            autoComplete="on" 
            style={{
            position: 'absolute',
            top: '250px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '18px',
            fontFamily: 'Roboto',
            opacity: 1,
            border: 'none',
            width: '63%',
            border: 'none',
            fontSize: 'medium',
            borderBottom: '1px solid #68686E',
            
          }} />
          
        <input
            type={showPassword ? "text" : "password"} 
            name="password" 
            required="required" 
            value={password}
            onChange={(e) => setPassword(e.target.value)} 
            className={loginstyles.loginInput} 
            placeholder="Password" 
            autoComplete="on" 
            style={{
            position: 'relative',
            top: '311px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '18px',
            fontFamily: 'Roboto',
            opacity: 1,
            border: 'none',
            width: '63%',
            border: 'none',
            fontSize: 'medium',
            borderBottom: '1px solid #68686E',
          }} />
           <div
              onClick={togglePassword}
              style={{
                position: 'absolute',
                left: '76%',
                top: '59%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#68686e',
              }}
      >
        {showPassword ? (
          <FaEye
            style={{
              fontSize: '20px',
              color: '#00000', // Set color
              transition: 'color 0.3s ease', // Smooth color transition
            }}
          />
        ) : (
          <FaEyeSlash
            style={{
              fontSize: '20px',
              color: '#00000', // Set color
              transition: 'color 0.3s ease', // Smooth color transition
            }}
          />
        )}
      </div>

        {/* Forgot Password */}
        <div
          style={{
            position: 'absolute',
            top: '365px',
            left: '70%',
            transform: 'translateX(-50%)',
            color: '#F8BD55',
            fontSize: '14px',
            fontFamily: 'Arial Rounded MT Bold',
            cursor: 'pointer',
          }}
        >
          Forgot password?
        </div>

        {/* Buttons */}
        <button
          style={{
            position: 'absolute',
            top: '410px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '280px',
            height: '35px',
            background: '#F8BD55',
            borderRadius: '40px',
            textAlign: 'center',
            lineHeight: '35px',
            color: 'white',
            fontSize: '20px',
            fontFamily: 'Arial Rounded MT Bold',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
       {/* <div
          style={{
            position: 'absolute',
            top: '480px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '280px',
            height: '35px',
            background: '#F8BD55',
            borderRadius: '40px',
            textAlign: 'center',
            lineHeight: '35px',
            color: 'white',
            fontSize: '20px',
            fontFamily: 'Arial Rounded MT Bold',
            cursor: 'pointer',
          }}
        >
          OTP Login
        </div>*/}
      </div>
    </div>
    </form>
  );
};

export default Login;
