import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../redux/slice/playerSlice';

const CreateUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    var createData = {
        email:'samantha@tralala.co.in',
        password:"Pickleball@123",
        firstname:'Samantha',
        lastname:"",
        contact_number:'',
        team_id:5,
        user_type:2,
    };
    dispatch(createUser(createData));
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    {/* var createData = {
    //     email:event.target.email,
    //     password:bcryptPassword,
    //     firstname:req.body.firstname,
    //     lastname:req.body.lastname,
    //     contact_number:req.body.contact_number,
    //     userType:req.body.user_type,
    // };*/}
    //     var createData = {
    //     email:'pune@wpbl.com',
    //     password:"123456",
    //     firstname:'Team',
    //     lastname:"Pune",
    //     contact_number:'',
    //     user_type:1,
    // };
    var createData = {
        email:'admin@wpbl.com',
        password:"P!ckl3w0rld",
        firstname:'WPBL',
        lastname:"Admin",
        contact_number:'',
        user_type:1,
    };
    dispatch(createUser(createData));
  };
  const blur = require('../../assets/images/blur.jpg');
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <div>
      
    </div>
  );
};

export default CreateUser;
