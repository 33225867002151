// src/features/api/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {serverCallOther} from '../../modules/serverCall'
import Swal from 'sweetalert2'

export const teamList = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/all-player-wish`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allTeam = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`teams/all-teams`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const allTeamList = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`teams/all-teams-list`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const myTeam = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`teams/get-team-player`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const wishList = createAsyncThunk(
  'api/createUser',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'PUT',
          url:`common/create-user/`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.message;
      if(Array.isArray(errorMSG)){
        var errorMSGData = [];
        for (var i = 0; i < errorMSG.length; i++) {
          errorMSGData.push(errorMSG[i].msg); 
        }
        errorMSG = errorMSGData;
      }
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

export const createTeam = createAsyncThunk(
  'api/createTeam',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`teams/create-team`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.message;
      if(Array.isArray(errorMSG)){
        var errorMSGData = [];
        for (var i = 0; i < errorMSG.length; i++) {
          errorMSGData.push(errorMSG[i].msg); 
        }
        errorMSG = errorMSGData;
      }
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

const teamSlice = createSlice({
  name: 'api',
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(teamList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(teamList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(teamList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default teamSlice.reducer;
