import Auth from '../Auth'
import axios from 'axios'
import qs from 'qs'
import serverUrl from '../../configs/constant'
import jwt_decode from "jwt-decode"
import Swal from 'sweetalert2'

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const URL = serverUrl.serverUrl

export const  serverCall = (config) => {
  //header authorization
    config.headers = {
      "Content-Type":config.contentType,
      "Accept":config.accept
    }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      console.log('error.response',error.response);
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      if(error.response.status===401){
        
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid credentials!",
        });
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}

export const serverCallOther = (config) => {
  //header authorization
  const token = Auth.getToken()
  if (token) {
    config.headers = {
      "authorization": token,
      "Content-Type":config.contentType,
      "Accept":config.accept
    }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      if(error.response.status===401){
        Auth.logout()
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invalid credentials!",
        });
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
  }
}
