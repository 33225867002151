const serverCall ={
	// serverUrl:'http://localhost:8080/api/',
	// imageUrlProfile:'http://localhost:8080/uploads/player_profile_photo/',
	// imageUrlTeam:'http://localhost:8080/uploads/teamlogo/',
	// serverUrl:'http://165.22.223.239:8080/api/',
	// imageUrlProfile:'http://165.22.223.239:8080/uploads/player_profile_photo/',
	// imageUrlTeam:'http://165.22.223.239:8080/uploads/teamlogo/',
	serverUrl:'https://playerprofiles.wpbl.org:8080/api/',
	imageUrlProfile:'https://playerprofiles.wpbl.org:8080/uploads/player_profile_photo/',
	imageUrlTeam:'https://playerprofiles.wpbl.org:8080/uploads/teamlogo/',
} 


export default serverCall;