
class Auth {
  getToken() {
    return localStorage.getItem('token');
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    window.location.href = "/";
  }
}
export default new Auth()