import React, { useState, useEffect } from 'react';
import useUser from '../../hooks/useUser';
import { allTeamList } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';

const AllTeam = () => {
  const token = useUser();
  var imageUrl = serverUrl.imageUrlProfile;
  var imageUrlTeam = serverUrl.imageUrlTeam;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const result = await dispatch(playerList());
  // console.log('in',result);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        var datapost = {
          user_id:token.userId
        } ;
        const jsonData = await dispatch(allTeamList(datapost));
        console.log('team.playerData[0].name',jsonData.payload[0].playerData);
        setData(jsonData.payload);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

    const handleNavigate = (player) => {
      navigate('/player-detail', { state: { player } });
    };

    const logo = require('../../assets/images/wpbl-logo.webp');
    const profileIcon = require('../../assets/images/icons8-profile-picture-100.png');
    return (
      <span>
      {data.length > 0 ?
        data.map((team,index) => (
        <div style={{width: 1890, height: 200, left: 15, top: 202, position: 'absolute'}} key={index}>
        <div style={{width: 1890, height: 200, left: 0, top: 0, position: 'absolute', background: '#F9FEFF', borderRadius: 10, border: '3px #464255 solid'}} />
        <div style={{width: 226, height: 194, left: 3, top: 3, position: 'absolute', background: '#D9D9D9', borderTopLeftRadius: 7, borderTopRightRadius: 7}} />
        <img style={{width: 130, height: 130, left: 51, top: 9, position: 'absolute'}} src={team.teamLogo !== null ? imageUrlTeam+team.teamLogo : logo} />
        <div style={{left: 31, top: 147, position: 'absolute', color: '#464255', fontSize: 25, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{team.name}</div>
        {team.playerData.length > 0 ?
          team.playerData.map((player,indexData) => (

        <div style={{width: 832, height: 194, left: 229, top: 3, position: 'absolute', background: 'white'}} key={indexData}>
        {player.player_id !== 0 ?
          <span>
          <div style={{left: 339, top: 166, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 20, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{player.age}</div>
          <div style={{width: 22.02, height: 0, left: 329.50, top: 189.01, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '2px #464255 solid'}}></div>
          <img style={{width: 30, height: 30, left: 319, top: 164, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0'}} src="https://via.placeholder.com/30x30" />
          <img style={{width: 40, height: 20, left: 233, top: 10, position: 'absolute'}} src={player.nationality !=='' ? require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`) : '' } />
          <div style={{left: 293, top: 131, position: 'absolute', color: '#464255', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>{player.player_name}</div>
          <img style={{width: 110, height: 110, left: 278, top: 13, position: 'absolute', borderRadius: 110}} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : profileIcon} />
          </span>
          :""} 
        </div>
      ))
      :""}
    </div>
   ))
   :""}
   </span>
    );
};

export default AllTeam;