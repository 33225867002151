import './App.css';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import Layout from './components/Layout';
import ProtectedRoute from './modules/Auth/ProtectedRoute';
import PrivateRoute from './modules/Auth/PrivateRoute';
import TeamRoute from './modules/Auth/TeamRoute';
import Login from "./pages/Login/login";
import CreateUser from "./pages/CreateUser/createUser";
import PlayerList from "./pages/PlayerList/playerList";
import PlayerDetails from "./pages/PlayerDetails/playerDetails";
import MyTeam from "./pages/MyTeam/myTeam";
import AllTeam from "./pages/AllTeam/allTeam";
import CreateTeam from "./pages/CreateTeam/createTeam";
import WishList from "./pages/WishList/wishList";
import ChangePassword from "./pages/ChangePassword/changePassword";
import ErrorMessage from "./pages/ErrorMessage/errorMessage";


const App = () => {

  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Routes >
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        <Route path="/" element={<Login />}>
        </Route>
            <Route path="/mobile-not-supported" element={<ErrorMessage/>}>
            </Route>
          <Route element={<Layout />}>
            <Route path="/create-user/" element={<CreateUser/>}>
            </Route>
            <Route path="/create-team/" element={<CreateTeam/>}>
            </Route>
            <Route path="/player-list/" element={ <ProtectedRoute><PlayerList/> </ProtectedRoute>}>
            </Route>
            <Route path="/player-detail/" element={ <ProtectedRoute><PlayerDetails/> </ProtectedRoute>}>
            </Route>
            <Route path="/my-team/" element={ <TeamRoute><MyTeam/> </TeamRoute>}>
            </Route>
            <Route path="/change-password/" element={ <ProtectedRoute><ChangePassword/> </ProtectedRoute>}>
            </Route>
             <Route path="/wish-list/" element={ <TeamRoute><WishList/> </TeamRoute>}>
            </Route>
             <Route path="/all-team/" element={ <PrivateRoute><AllTeam/> </PrivateRoute>}>
            </Route>
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;