import React, { useState, useEffect } from 'react';

const ErrorMessage = () => {

  const blur = require('../../assets/images/blur.jpg');
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
     <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
      {/* Background Image */}
      <img
        className="login-bg"
        src={blur}
        alt="Background"
        style={{ width: '100%', height: '100%', position: 'absolute' }}
      />

      {/* Login Box */}
      <div
        style={{
          width: '92%',
          height: '50%',
          position: 'absolute',
          top: '25%',
          left: '50%',
          transform: 'translateX(-50%)',
          background: '#FFFDFD',
          boxShadow: '10px 10px 10px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '20px',
        }}
      >
        {/* Profile Image */}
        <img
          src={logo}
          alt="Profile"
          style={{
            width: '180px',
            height: '180px',
            position: 'absolute',
            top: '16px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />

        {/* Labels */}
        <div
          style={{
            position: 'absolute',
            top: '200px',
            left: '50%',
            width: '98%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '21px',
            fontFamily: 'Roboto',
            fontWeight: '800',
          }}
        >
          <h1 style={{textAlign: 'center'}}>Welcome to WPBL</h1>
          <p style={{textAlign: 'center'}}>This content will not display on mobile devices. Please access using desktop/laptop/tablet</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
