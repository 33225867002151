import React, { useState, useEffect } from 'react';
import loginstyles from './changePassword.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2';
import useUser from '../../hooks/useUser';

const ChangePassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useUser();
  const { isLoading, error } = useSelector((state) => state.auth);

   
  const handleSubmit = async  (e) => {
    e.preventDefault();
    if (password !== confirmpassword) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Passwords don't match",
        });
    } else {
      var datapost = {
        password:password,
        user_id:token.userId
      }
      const result = await dispatch(changePassword(datapost));
      if (result.meta.requestStatus === 'fulfilled') {
        Swal.fire({
          icon: "success",
          title: "your password has been successfully changed",
          showConfirmButton: false,
          timer: 1500
        });
        setPassword('');
        setConfirmPassword('');
      }
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const blur = require('../../assets/images/blur.jpg');
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <form onSubmit={handleSubmit}>
     <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Background Image */}

      {/* Login Box */}
      <div
        style={{
          width: '400px',
          height: '550px',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          background: '#FFFDFD',
          boxShadow: '10px 10px 10px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '20px',
        }}
      >

        {/* Labels */}
        <div
          style={{
            position: 'absolute',
            top: '70px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '21px',
            fontFamily: 'Roboto',
            fontWeight: '800',
          }}
        >
        <span style={{ fontWeight: '400' }}>Change Password</span>
        </div>

        {/* Input Fields */}
          <input 
            type={showPassword ? "text" : "password"} 
            name="password" 
            required="required" 
            value={password}
            onChange={(e) => setPassword(e.target.value)} 
            className={loginstyles.loginInput} 
            placeholder="Password" 
            autoComplete="on"
            style={{
            position: 'absolute',
            top: '202px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '18px',
            fontFamily: 'Roboto',
            opacity: 1,
            border: 'none',
            width: '63%',
            border: 'none',
            fontSize: 'medium',
            borderBottom: '1px solid #68686E',
            
          }} />
           <div
              onClick={togglePassword}
              style={{
                position: 'absolute',
                left: '76%',
                top: '39%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#68686e',
              }}
          >
            {showPassword ? (
              <FaEye
                style={{
                  fontSize: '20px',
                  color: '#00000', // Set color
                  transition: 'color 0.3s ease', // Smooth color transition
                }}
              />
            ) : (
              <FaEyeSlash
                style={{
                  fontSize: '20px',
                  color: '#00000', // Set color
                  transition: 'color 0.3s ease', // Smooth color transition
                }}
              />
            )}
          </div>
          
        <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmpassword" 
            required="required" 
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)} 
            className={loginstyles.loginInput} 
            placeholder="Confirm Password" 
            autoComplete="on" 
            style={{
            position: 'relative',
            top: '264px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#464255',
            fontSize: '18px',
            fontFamily: 'Roboto',
            opacity: 1,
            border: 'none',
            width: '63%',
            border: 'none',
            fontSize: 'medium',
            borderBottom: '1px solid #68686E',
          }} />
           <div
              onClick={togglePasswordConfirm}
              style={{
                position: 'absolute',
                left: '76%',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#68686e',
                zIndex:1,
              }}
      >
        {showConfirmPassword ? (
          <FaEye
            style={{
              fontSize: '20px',
              color: '#00000', // Set color
              transition: 'color 0.3s ease', // Smooth color transition
            }}
          />
        ) : (
          <FaEyeSlash
            style={{
              fontSize: '20px',
              color: '#00000', // Set color
              transition: 'color 0.3s ease', // Smooth color transition
            }}
          />
        )}
      </div>

        {/* Buttons */}
        <button
          style={{
            position: 'absolute',
            top: '362px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '280px',
            height: '35px',
            background: '#F8BD55',
            borderRadius: '40px',
            textAlign: 'center',
            lineHeight: '35px',
            color: 'white',
            fontSize: '20px',
            fontFamily: 'Arial Rounded MT Bold',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          {isLoading ? 'Logging in...' : 'Submit'}
        </button>
      </div>
    </div>
    </form>
  );
};

export default ChangePassword;
