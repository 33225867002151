import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment';
import './playerDetails.css';
import { useLocation, useNavigate,useSearchParams  } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { playerDetailsList,addWishList } from '../../redux/slice/playerSlice';
import { useDispatch, useSelector } from 'react-redux';
import serverUrl from '../../configs/constant';
import { SiSinglestore } from 'react-icons/si';
import ReactPlayer from 'react-player';
import he from 'he';

const PlayerDetails = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = useUser();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  var imageUrl = serverUrl.imageUrlProfile;
  const player = searchParams.get('player');


  const [data, setData] = useState([]);


  useEffect(() => {
    handlePlayer();
  }, []);

    const handlePlayer = () => {
    const fetchData = async () => {
      try {
        var datapost = {
          player_id:player,
            team_id:token.teamId
        } ;
        const jsonData = await dispatch(playerDetailsList(datapost));
        setData(jsonData.payload);
        console.log('data.responseDataYouTube',jsonData.payload.responseDataYouTube[0])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }

  const handleAddWish = (event) => {
    var player_id = event;
    const fetchData = async () => {
      try {
        var datapost = {
          team_id:token.teamId,
          player_id:player_id,
        } ;
        const jsonData = await dispatch(addWishList(datapost));
        handlePlayer();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  };

    const isVideoUrl = async (url) => {
      // const videoExtensions = /\.(mp4|avi|mov|mkv|webm|flv|wmv|ogg)$/i;
      // console.log('in');
      // if (videoExtensions.test(url)) {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          const contentType = response.headers.get('Content-Type');
          console.log('contentType',contentType)
          // return contentType && contentType.startsWith('video/');
        } catch (error) {
          console.error('Error checking video URL:', error);
          return false;
        }
      // }
      return false;
    };


    const medals = require('../../assets/images/Medals.png');
    const silver = require('../../assets/images/silver-medal.png');
    const bronze = require('../../assets/images/bronze-medal.png');
    const gold = require('../../assets/images/gold-medal.png');
    const bar = require('../../assets/images/bar.png');
    const ball = require('../../assets/images/ball.png');
    const instagram = require('../../assets/images/instagram.png');
    const twitter = require('../../assets/images/twitter.png');
    const Facebook = require('../../assets/images/Facebook.png');
    const taiwan = require('../../assets/country_flags/TAIWAN.png');
    const usa = require('../../assets/country_flags/UNITED STATES.png');
    const orangePlayer = require('../../assets/images/orangePlayer.png');
    const orangePlayerR = require('../../assets/images/orangePlayerR.png');
    const greyR = require('../../assets/images/greyR.png');
    const grey = require('../../assets/images/player.png');
    const heart = require('../../assets/images/heart.png');
    const heartFill = require('../../assets/images/heartFilled.png');
    const single = require('../../assets/images/orange_extracted.png');
    const Doubles = require('../../assets/images/orange_parts.png');
    const disDoubles = require('../../assets/images/gray_parts.png');
    const tape = require('../../assets/images/Tape.png');
    const male_profile = require('../../assets/images/male_profile.png');
    const female_profile = require('../../assets/images/female_profile.png');

    return (

      <div>
      {data.length !== 0 ?
          <>
            <div style={{width:'100%', position: "relative", display:'flex', flexDirection:'row', top:10, height:220}}>
              <div style={{
                width: '240px', height: "230px", justifyContent:'center', display:'flex', left: "7%", position: "absolute", transform: "rotate(-6deg)", background: "white", boxShadow: "10px 14px 45px rgba(0, 0, 0, 0.25)", zIndex:3
                  }}>
                {/* Player Image */}
                <img style={{
                  width: "230px", height: "210px", left: "2.5%", top: "10px", position: "absolute", objectFit:'cover', zIndex:1,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                }} src={data.profilePhoto !== null && data.profilePhoto !=='' ? imageUrl+data.profilePhoto : (data.gender ==='Female' ? female_profile : male_profile)} alt="Player Picture" />
                <img style={{
                  width: "50px", height: "70px", zIndex:1, position:'relative', bottom:40,
                  transform: "rotate(0deg)"
                }} src={tape} alt="Tape" />
              </div>
              <div style={{width:"20%", left:'27%', display:'flex', position:'relative', flexDirection:'column', gap:15}}>
              <div style={{display:'flex', flexDirection:'row', position:'relative', width: "100%", alignItems:'center', gap:30}}>
                  <img
                  style={{ position: "relative",width: "25%", boxShadow: "6px 4px 4px rgba(0, 0, 0, 0.25)", height:'fit-content'}}
                  src={require(`../../assets/country_flags/${data.nationality.toUpperCase()}.png`)} alt="CA" />
                   <img onClick={()=>handleAddWish(data.id)} style={{width: 35, height: 35, left: '41.5%', top: 165}}  src={data.isInWishlist === true ? heartFill : heart} />
                  {/* Player Name */}
                  </div>
                    <div style={{
                      color: "#464255", fontSize: "27px", textTransform:'uppercase',
                      fontFamily: "Roboto", fontWeight: 400
                        }}>{data.name}
                    </div>
                <div style={{ width: "100%", position: "relative" }}>
                  <div style={{
                    color: "#464255", fontSize: "18px", fontFamily: "Roboto", fontWeight: 600, wordWrap: "break-word", lineHeight: 1.5
                  }}>
                    Player<span style={{ opacity: 0.7, position: "absolute", left:'35%' }}>{data.playingHand}</span>
                  </div>
                  <div style={{
                    color: "#464255", fontSize: "18px", fontFamily: "Roboto", fontWeight: 600, wordWrap: "break-word", lineHeight: 1.5
                  }}>
                    Age<span style={{ opacity: 0.7, position: "absolute", left:'35%'}}>{data.age+" years"}</span>
                  </div>
                  <div style={{
                    color: "#464255", fontSize: "18px", fontFamily: "Roboto", fontWeight: 600, wordWrap: "break-word", lineHeight: 1.5
                  }}>
                    Country<span style={{ opacity: 0.7, position: "absolute", left:'35%'}}>{data.nationality}</span>
                  </div>
                </div>
              </div>
            <div style={{
              width: "13%", height: "0px", left: "20%", top: 110, position: "relative",
              transform: "rotate(90deg)", opacity: 0.5, border: "1px #464255 solid"
            }}></div>
            <div style={{width:'20%', display:"flex", flexDirection:'column', position:'relative', left:'11.75%', gap:70, position:'relative', }}>
                     <div style={{
                      position:'relative', top:40,
                      color: "#464255", fontSize: "25px",
                      fontFamily: "Roboto", fontWeight: 600, textAlign:'center'
                        }}>Medal Tally
                    </div>
                    <div style={{display:'flex', flexDirection:'row', position:'relative', width: "50%", left: '25%', gap:'10px', alignItems:'center', }}>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={gold} />
                          <div style={{
                            color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                          }}>{data.goldTotal !=='' && data.goldTotal !== null ? data.goldTotal:0 }
                          </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={silver} />
                           <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.silverTotal !=='' && data.silverTotal !== null ? data.silverTotal:0 }
                        </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={bronze} />
                          <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.bronzeTotal !=='' && data.bronzeTotal !== null ? data.bronzeTotal:0 }
                        </div>
                      </div>
                        
                  </div>
            </div>
            <div style={{
              width: "13%", height: "0px", left: "4%", top: 110, position: "relative",
              transform: "rotate(90deg)", opacity: 0.5, border: "1px #464255 solid"
            }}></div>
            <div style={{display:'flex', flexDirection:'column', position:'relative', width:'30%', gap:45}}>
              <div style={{display:'flex', flexDirection:'column', gap:12,position:'relative', top:30, justifyContent:'center', height:'30%'}}>
                    <div style={{
                      width:'100%', color: "#464255", fontSize: "20px",fontFamily: "Roboto",
                      fontWeight: 600, wordWrap: "break-word", textAlign:'center'
                      }}>DUPR Rating
                    </div>
                    <div style={{
                      width:'100%',color: "#464255", fontSize: "18px", fontFamily: "Roboto",
                      fontWeight: 600, wordWrap: "break-word", textAlign:'center'
                      }}>ID: {data.duprId}
                    </div>
                    </div>
                    
                      <div style={{display:'flex', flexDirection:'row', position:'relative'}}>
                        <div style={{display:'flex', flexDirection:'column', width:'50%', height:'100%', position:'relative', alignItems:'center'}}>
                        <img style={{
                            width: "25%"
                          }} src={single} />
                          <div style={{
                            color: "#464255", fontSize: "18px",fontFamily: "Roboto",
                            fontWeight: 600, wordWrap: "break-word",textAlign:'center',
                            }}>SINGLES
                          </div>
                          <div style={{
                          color: "#464255", fontSize: "18px",fontFamily: "Roboto",textAlign:'center',
                          fontWeight: 600, wordWrap: "break-word"
                          }}>{data.duprRatingSingles}
                        </div>
                        </div>
                        <div style={{display:'flex', width:'50%', height:'100%', flexDirection:'column', position:'relative', alignItems:'center'}}>
                        <img style={{
                            width: "70%"
                          }} src={Doubles} />
                          <div style={{
                              color: "#464255", fontSize: "18px", left: "15%", fontFamily: "Roboto",
                              fontWeight: 600, wordWrap: "break-word", textAlign:'center'
                              }}>DOUBLES
                            </div>
                            <div style={{
                          color: "#464255", fontSize: "18px",left: "35%", fontFamily: "Roboto",
                          fontWeight: 600, wordWrap: "break-word", textAlign:'center'
                          }}>{data.duprRatingDoubles}
                        </div>
                        </div>
                 
              </div>
            </div>
          </div> 
          <div style={{display:'contents', position:'relative', top:20}}>
           <div style={{display:'flex', width: '10%', position:'absolute', height: '74px', left:'6%', background: '#F9AF3B', borderRadius: '5px', transform: 'skew(-40deg)', zIndex:'2', boxShadow: "10px 5px 8px rgba(0, 0, 0, 0.25)"}} />

            <Tabs onSelect={(index) => setTabIndex(index)}>
              <TabList>
                
                    <div style={{display:'flex', listStyle:'none', width: '100%', marginLeft:'-22px', marginTop:10, marginBottom:10, position:'relative', height: '50px', background: '#29ABE2', borderRadius: '3px', zIndex:'0',  clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}} >

                      <Tab>
                        <div style={{width: '4%', height: '0px', left: '43%', top: '25px', position: 'absolute', transform: 'rotate(-60deg)', border: '1px white solid'}}/>
                        <div style={{position: 'absolute', left: '44%', textAlign:'center', width:'15%', textTransform: 'uppercase', top: '25%',cursor: 'pointer', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}  className={tabIndex === 0 ? "tablinks tabColor" : "tablinks tabColorOther"}>ABOUT ME</div>
                      </Tab>
                      <Tab>
                        <div style={{width: '4%', height: '0px', left: '56%', top: '25px', position: 'absolute', transform: 'rotate(-60deg)', border: '1px white solid'}} />
                        <div style={{position: 'absolute', left: '58%', textAlign:'center', width:'14%', top: '25%',textTransform: 'uppercase', cursor: 'pointer', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word',}} className={tabIndex === 1 ? "tablinks tabColor" : "tablinks tabColorOther"} >Skill Details</div>
                      </Tab>
                      <Tab>
                        <div style={{width: '4%', height: '0px', left: '70%', top: '25px', position: 'absolute', transform: 'rotate(-60deg)', border: '1px white solid'}} />
                        <div style={{position: 'absolute', left: '72%', top: '25%', width:'12%', textAlign:'center', textTransform: 'uppercase', fontSize: '20px',cursor: 'pointer', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word',}} className={tabIndex === 2 ? "tablinks tabColor" : "tablinks tabColorOther"}>Results</div>
                      </Tab>
                      <Tab>
                        <div style={{width: '4%', height: '0px', left: '82%', top: '25px', position: 'absolute', transform: 'rotate(-60deg)', border: '1px white solid'}} />
                        <div style={{position: 'absolute', left: '84%', top: '25%', width:'15%', textAlign:'center', textTransform: 'uppercase', fontSize: '20px',cursor: 'pointer', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}} className={tabIndex === 3 ? "tablinks tabColor" : "tablinks tabColorOther"}>Media &amp; Videos</div>
                      </Tab>
                    </div>
                
            </TabList>

            <TabPanel>
            <div style={{margin:'20px', top: 20, position: 'relative' }}>
              <div style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                ABOUT ME
              </div>
              <div style={{ position: 'relative', top: '25px', color: '#464255', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word' }}>
                {he.decode(data.shortBio)}  
              </div>
            </div>
              <div style={{display:'flex', flexDirection:'row', left:'1%', width:'100%', top:80, height:45, position:'relative', gap:'7%'}}>
                <div style={{ width: '40%', height: '45px', position: 'relative' }}>
                  <div style={{display:'flex',background: '#CACAD3', borderRadius: '3px',  clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                  <img style={{ width: 45, top: '6%', height: '40px', left: '5.5%', position: 'relative'}} src={ball} alt="ball" />
                  <div style={{width:'80%', position: 'relative', top: '9px', left: '8%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>
                    PERSONAL DETAILS
                  </div>
                </div>
                </div>
                <div style={{ width: '50%',height: '40px', position: 'relative' }}>
                  <div style={{ width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)' }}></div>
                  <img style={{ width: '45px', top: '3%', height: '40px', left: '5.5%', position: 'absolute' }} src={ball} alt="ball" />
                  <div style={{ position: 'absolute', top: '9px', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                    OVERALL PERFORMANCES
                  </div>
                </div>
              </div>
                
            
            <div style={{display:'flex', flexDirection:'column', gap:'15px', width: '30%', top: 100, left: '5%', height: '150px', position: 'relative'}}>
              <div style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word' }}>
                Date of Birth: <span style={{ opacity: 0.7, position: 'absolute', left: '32%' }}>{moment(data.DOB).format('Do MMMM YYYY')}</span>
              </div>
              <div style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word', lineHeight: 1.5 }}>
                Gender<span style={{ opacity: 0.7, position: 'absolute', left: '32%' }}>{data.gender}</span>
              </div>
              <div style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word', lineHeight: 1.5  }}>
                Country<span style={{ opacity: 0.7, position: 'absolute', left: '32%' }}>{data.nationality}</span>
              </div>

            </div>
            
            <div style={{ width: '20%',left: '50%', top:'-50px', height: '30px', position: 'relative' }}>
              <span style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                Matches Played: 
              </span>
              <span style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word' }}>
                {data.playedTotalMatches}
              </span>
              <span style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                {' '}| Win Rate: 
              </span>
              <span style={{ color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word' }}>
                {data.playedTotalWinRate !== null ? data.playedTotalWinRate+'%' : '0%'}
              </span>
            </div>

            <div>
              <div style={{width: '44%', height: '100%',top:'-50px', left: '50%', position: 'relative'}}>
                <div style={{width: '650px', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                <div style={{width: data.playedTotalWinRate+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                  <img style={{width: '30px', height: '27px', left: '95%', top: '-8px', position: 'absolute'}} src={ball} />
                </div>
              </div>
              <div style={{top: '-15px', position: 'relative'}}>
                <div style={{left: '50%', top: '0px', position: 'absolute', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Win:{data.playedTotalWon}</div>
                <div style={{left: '70.8%', top: '0px', position: 'absolute', color: '#464255', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word'}}>{data.playedTotalWinRate !== null ? data.playedTotalWinRate+'%' : "0%" }</div>
                <div style={{left: '90%', top: '0px', position: 'absolute', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>Lost:{data.playedTotalLoss}</div>
              </div>
              <div style={{opacity: '0.70', width: '15%', top: '10px', left: '54.5%', height: '20px', position: 'relative'}}>
                <span style={{color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>2024 | </span>
                <span style={{color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word'}}>
                   {'Played '+data.playedTwoFourMatches+' Matches'} 
                </span>
              </div>
              <div style={{width: '20%', height: '100%', top: '10px', left: '50%', position: 'relative'}}>
                <div style={{width: '100%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                <div style={{width: data.playedTwoFourWinRate+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                  <img style={{width: '30px', height: '27px', left: '95%', top: '-8px', position: 'absolute'}} src={ball} />
                </div>
              </div>
              <div style={{left: '50%', top: '50px', position: 'relative'}}>
                <div style={{left: '0%', top: '0px', position: 'absolute', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Win:{data.playedTwoFourWon}</div>
                <div style={{left: '9%', top: '0px', position: 'absolute', color: '#464255', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word'}}>{data.playedTwoFourWinRate !== null ? data.playedTwoFourWinRate+'%' : "0%"}</div>
                <div style={{left: '17%', top: '0px', position: 'absolute', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>Lost:{data.playedTwoFourLoss}</div>
              </div>
              <div style={{opacity: '0.70', width: '15%', top: '-5px', left: '78%', height: '20px', position: 'relative'}}>
                <span style={{color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>2023 | </span>
                <span style={{color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word'}}>
                  {'Played '+data.playedTwoThreeMatches+' Matches'} 
                </span>
              </div>
              <div style={{width: '20%', height: '100%', top: '-10px', left: '73.25%', position: 'relative'}}>
                <div style={{width: '300px', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                <div style={{width: data.playedTwoThreeWinRate+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                  <img style={{width: '30px', height: '27px', left: '95%', top: '-8px', position: 'absolute'}} src={ball} />
                </div>
              </div>
              <div style={{left: '73.4%', top: '30px', position: 'relative'}}>
                <div style={{left: '0%', top: '0px', position: 'absolute', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Win:{data.playedTwoThreeWon}</div>
                <div style={{left: '9%', top: '0px', position: 'absolute', color: '#464255', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word'}}>{data.playedTwoThreeWinRate !== null ? data.playedTwoThreeWinRate+'%' : "0%"}</div>
                <div style={{left: '17%', top: '0px', position: 'absolute', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>Lost:{data.playedTwoThreeLoss}</div>
              </div>
            </div>
          
            <div>
            <div style={{display:'flex', flexDirection:'row', left:'1%', width:'100%', top:80, height:45, position:'relative', gap:'7%'}}>
                <div style={{ width: '40%', height: '45px', position: 'relative' }}>
                  <div style={{display:'flex',background: '#CACAD3', borderRadius: '3px',  clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                  <img style={{ width: 45, top: '6%', height: '40px', left: '5.5%', position: 'relative'}} src={ball} alt="ball" />
                  <div style={{width:'80%', position: 'relative', top: '9px', left: '8%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>
                    PERSONAL HISTORY
                  </div>
                </div>
                </div>
                <div style={{ width: '50%',height: '40px', position: 'relative' }}>
                  <div style={{ width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)' }}></div>
                  <img style={{ width: '45px', top: '3%', height: '40px', left: '5.5%', position: 'absolute' }} src={ball} alt="ball" />
                  <div style={{ position: 'absolute', top: '9px', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                    CAREER HIGHLIGHTS
                  </div>
                </div>
                </div>
              <div style={{display: 'flex', flexDirection: 'row', top: 75, position:'relative', gap:'4%'}}>
                <div style={{width: '41%',left: '1%', position: 'relative'}}>
                  <div style={{top: '15px', position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>
                  {data.personalHistory}
                  </div>
                </div>
                <div style={{width: '45%',left: '5%', position: 'relative'}}>
                  <div style={{top: '15px', position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>
                    {data.careerHighlights}
                  </div>
                </div>
              </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', left:'1%', width:'100%', top:150, height:45, position:'relative', gap:'7%'}}>
                <div style={{ width: '40%', height: '45px', position: 'relative' }}>
                  <div style={{display:'flex',background: '#CACAD3', borderRadius: '3px',  clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                  <img style={{ width: 45, top: '6%', height: '40px', left: '5.5%', position: 'relative'}} src={ball} alt="ball" />
                  <div style={{width:'80%', position: 'relative', top: '9px', left: '8%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>
                    UNIQUE SELLING POINT
                  </div>
                </div>
                </div>
                <div style={{ width: '50%',height: '40px', position: 'relative' }}>
                  <div style={{ width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)' }}></div>
                  <img style={{ width: '45px', top: '3%', height: '40px', left: '5.5%', position: 'absolute' }} src={ball} alt="ball" />
                  <div style={{ position: 'absolute', top: '9px', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }}>
                    TEAM EXPERIENCE
                  </div>
                </div>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'row',  top: '160px', position:'relative'}}>
                <div style={{width: '43%', left: '1%', position: 'relative'}}>
                  <div style={{position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>{data.uniqueSellingPoint}
                  </div>
                </div>
                <div style={{width: '43%', left: '7%', position: 'relative'}}>
                  <div style={{ position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>{data.teamExperience}
                  </div>
                </div>
                </div>
             

              <div style={{display: 'flex',top: '200px', position:'relative', flexDirection: 'column'}}>
                <div style={{width: '40%', left: '1%', height: '45px', position: 'relative'}}>
                  <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}} />
                  <img style={{width: '45px', top: '6%', height: '40px', left: '5.5%', position: 'absolute'}} src={ball} alt="ball" />
                  <div style={{position: 'absolute', top: '12px', left: '15%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>SOCIAL REACH</div>
                </div>
                <div style={{top: '15px', position: 'relative'}}>
                  <img style={{width: '60px', height: '60px', left: '5%', top: '15px', position: 'absolute', borderRadius: '35px',cursor: 'pointer'}} src={Facebook}  onClick={() => window.open('https://www.facebook.com/'+data.socialMedia.FacebookHandle, '_blank')} />
                  <div style={{left: '2.5%',height:40, top: '100px', position: 'absolute', color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{data.socialMedia.FacebookFollowers+" FOLLOWERS"} </div>
                  <img style={{width: '60px', height: '60px', left: '19%', top: '15px', position: 'absolute', borderRadius: '35px',cursor: 'pointer'}} src={instagram} onClick={() => window.open('https://www.instagram.com/'+data.socialMedia.InstagramHandle, '_blank')} />
                  <div style={{left: '16%', top: '100px', position: 'absolute', color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{data.socialMedia.InstagramFollowers+" FOLLOWERS"}</div>
                  <img style={{width: '60px', height: '60px', left: '33%', top: '15px', position: 'absolute', borderRadius: '35px',cursor: 'pointer'}} src={twitter} onClick={() => window.open('https://x.com/'+data.socialMedia.XHandle, '_blank')} />
                  <div style={{left: '31%', top: '100px', position: 'absolute', color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{data.socialMedia.XFollowers+" FOLLOWERS"}</div>
                </div>
              
              
              
              
            </div>
            <div style={{display: 'flex', flexDirection: 'column', top: '160px', position:'relative'}}>
                <div style={{width: '50%', left: '47%', height: '45px', position: 'relative'}}>
                  <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)'}} />
                  <img style={{width: '45px', top: '6%', height: '40px', left: '5.5%', position: 'absolute'}} src={ball} alt="ball" />
                  <div style={{position: 'absolute', top: '12px', left: '14%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>OTHER RANKING</div>
                </div>
                
                <div style={{width:'40%', display: 'flex', left:'50%', flexDirection: 'column', top: '20px', position: 'relative'}}>
                  <div style={{display: 'flex',flexDirection: 'row',position: 'relative', gap:20}}>
                    <div style={{width: '50%',height: '35px', position: 'relative'}}>
                      <div style={{width: '100%', height: '100%', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                       <div style={{position: 'relative', top: '30%',textAlign:'center',color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Name</div>
                      </div>
                    </div>
                  
                    <div style={{width: '45%', left: '2%', height: '35px', position: 'relative'}}>
                      <div style={{width: '100%', height: '100%', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                        <div style={{position: 'relative', top: '30%',textAlign:'center',color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word',  transform: 'skew(45deg)'}}>Ranking
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div style={{width:'40%', display: 'flex', flexDirection: 'column', left:'50%', top: '40px', position:'relative', gap:10}}>
                  {data.otherRanking.length > 0 ?
                    data.otherRanking.map((rankingData,index) => (
                      rankingData.name !=="0" && rankingData.name !== 0 ?
                        <div style={{display: 'flex', flexDirection: 'row',position:'relative', height:25, width:'100%'}} key="index">
                          <div style={{width:'50%',color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{rankingData.name}</div>
                          <div style={{width:'50%', color: '#464255', textAlign:'center', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{rankingData.ranking}</div>
                        </div>
                        :""
                    ))
                  :""}
                </div>
              </div>
              <div style={{width: '100%', height: '20px', background: '#FBB13A',top:270, position: 'relative'}}>
                <div style={{color: '#464255', fontSize: '17px', textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700}}>© 2024 WPBL  *All data collected is based on the information provided by the players</div>
              </div>
            </TabPanel>
            <TabPanel>
            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative', gap:'10px'}}>
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
              <div style={{width: '80%', left: '2%', height: '45px', position: 'relative', top:'20px',  clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px'}} />
                <img style={{width: '40px', top: '15%', height: '35px', left: '5%', position: 'absolute'}} src={ball} alt="ball" />
                <div style={{position: 'absolute', top: '30%', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>PLAYING STYLE</div>
              </div>
              <div style={{display:'flex', flexDirection:'column', width:'100%', position:'relative', gap:'10px'}}>
              <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative', top:'40px', height:'75px'}}>
              <div style={{display:'flex', flexDirection:'column', width:'40%', position:'relative', left:'2%'}}>
                    
                    <div style={{width: '85%', height:'35px',position: 'relative',display:'flex'}}>
                      <div style={{width: '100%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                      <div style={{width: data.dropshot+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                        <img style={{width: '30px', height: '27px', left: '89%', top: '-8px', position: 'absolute'}} src={ball} />
                      </div>
                      </div>
                      <div style={{display:'flex',position: 'relative', flexDirection:'row', gap:'51%'}}>
                        <div style={{top: '0px', position: 'relative', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>3rd drop shot</div>
                        <div style={{top: '0px', position: 'relative', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>Drive</div>
                      </div>
                    </div>
                     
                 
                  <div style={{display:'flex', flexDirection:'column', width:'40%', position:'relative'}}>
                     
                    <div style={{width: '85%', height:'35px',position: 'relative',display:'flex'}}>
                      <div style={{width: '100%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                      <div style={{width: data.slice+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                        <img style={{width: '30px', height: '27px', left: '89%', top: '-8px', position: 'absolute'}} src={ball} />
                      </div>
                    </div>
                    <div style={{display:'flex',position: 'relative', flexDirection:'row', gap:'62%'}}>
                        <div style={{top: '0px', position: 'relative', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Slice</div>
                        <div style={{top: '0px', position: 'relative', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>Topspin</div>
                      </div>
                 </div>
                 </div>
                 
                 </div>
                 <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative', top:'40px'}}>
                   <div style={{display:'flex', flexDirection:'column', width:'40%', position:'relative', left:'2%'}}>
                     <div style={{width: '85%', height: '20px', position: 'relative', justifyContent:'center', display:'flex'}}>
                      <span style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>All court Player</span>
                      
                    </div>
                     
                    <div style={{width: '85%', height:'35px',position: 'relative',display:'flex'}}>
                      <div style={{width: '100%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                      <div style={{width: data.courtplayer+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                        <img style={{width: '30px', height: '27px', left: '89%', top: '-8px', position: 'absolute'}} src={ball} />
                      </div>
                      </div>
                      <div style={{display:'flex',position: 'relative', flexDirection:'row', gap:'77%'}}>
                        <div style={{top: '0px', position: 'relative', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>0</div>
                        <div style={{top: '0px', position: 'relative', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>10</div>
                      </div>
                    </div>
                 
                  <div style={{display:'flex', flexDirection:'column', width:'40%', position:'relative'}}>
                     <div style={{width: '85%', height: '20px', position: 'relative', justifyContent:'center', display:'flex'}}>
                      <span style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Dink Patience</span>
                      
                    </div>
                     
                    <div style={{width: '85%', height:'35px', position: 'relative',display:'flex'}}>
                      <div style={{width: '100%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#CACAD3', borderRadius: '10px'}} />
                      <div style={{width: data.dinkpatience+'%', height: '10px', left: '0px', top: '15px', position: 'absolute', background: '#64C314', borderRadius: '10px'}}>
                        <img style={{width: '30px', height: '27px', left: '89%', top: '-8px', position: 'absolute'}} src={ball} />
                      </div>
                    </div>
                    <div style={{display:'flex',position: 'relative', flexDirection:'row', gap:'77%'}}>
                        <div style={{top: '0px', position: 'relative', color: '#64C314', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>0</div>
                        <div style={{top: '0px', position: 'relative', color: '#9C9CA0', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word'}}>10</div>
                      </div>
                 </div>
                 </div>
                 </div>
                 <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                  <div style={{width: '80%', left: '5%', height: '45px', position: 'relative', top:'20px'}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)'}} />
                    <img style={{width: '40px', top: '15%', height: '35px', left: '5%', position: 'absolute'}} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '30%', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>FAVOURITE SHOTS</div>
                   </div>
                   <div style={{width:'85%', display: 'flex',flexDirection: 'row',left:'5%', top:'35px', position: 'relative', gap:'2%'}}>
                        <div style={{width: '46%',height: '35px', position: 'relative'}}>
                          <div style={{width: '100%', display:'flex', justifyContent:'flex-start', height: '100%', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                            <div style={{position: 'relative', top: '30%', left:'10%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Shot Name</div>
                          </div>
                        </div>
                    <div style={{width: '48%',height: '35px', position: 'relative'}}>
                      <div style={{width: '100%', height: '100%', display:'flex', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                       <div style={{position: 'relative', top: '30%', left:'10%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Link</div>
                      </div>
                    </div>
                  </div>
                  <div style={{width:'100%', display: 'flex', position:'relative', top:'45px', left:'8%', flexDirection: 'column',position:'relative', gap:10}}>
                        {data.favouriteShot.length > 0 ?
                          data.favouriteShot.map((shot,index) => (
                              <div style={{width:'100%', display: 'flex', flexDirection: 'row',position:'relative', gap:'4%'}} key={index}>
                                <div style={{width:'37%', color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{shot.name}</div>
                                <a href={shot.link}><div style={{width:'62%',color: '#464255', fontSize: '15px',textAlign:'left', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{shot.link}</div></a>
                              </div>
                          ))
                        :""}
                      </div>
              </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', top: '90px', position: 'relative', gap:'12%'}}>
                  <div style={{width: '40%', height: '45px', display:'flex', flexDirection:'row', position:'relative', left:'1%', gap:'12%', clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px'}}>
                    
                    <img style={{width: '40px', top: '12%', height: '35px', left: '5%', position: 'absolute'}} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '25%', left: '13%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>SPECIALITY</div>
                  </div>
                  </div>
                  <div style={{width: '40%', height: '45px', display:'flex', flexDirection:'row', gap:'12%',  transform: 'skew(-45deg)'}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px'}}>
                    <img style={{width: '40px', top: '10%', height: '35px', left: '4%', position:'relative',  transform: 'skew(45deg)' }} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '25%', left: '12%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>DESCRIBE YOUR PLAYING STYLE</div>
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex', left:'2%', flexDirection: 'row', top: '110px', position: 'relative', gap:'5%'}}>
                  <div style={{width: '40%', height: '100px'}}>
                    <div style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word', lineHeight: 2}}>Preferred Side Doubles:<span style={{opacity: '0.7', position: 'absolute', left: '14%'}}>{data.playingHand}</span>
                    </div> 
                    <div style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word', lineHeight: 2}}>Speciality:<span style={{opacity: '0.7', position: 'absolute', left: '14%'}}>{data.speciality}</span>
                    </div>
                  </div>
                
              
                <div style={{width: '40%',left: '7%', position: 'relative'}}>
                  <div style={{ position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>
                  {data.describePlayingStyle}
                  </div>
                </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', top: '120px', position: 'relative', gap:'12%'}}>
                  <div style={{width: '40%', height: '45px', position:'relative',left: '1%', clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px'}}>
                   
                    <img style={{width: '40px', top: '10%', height: '35px', left: '5%', position: 'absolute'}} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '25%', left: '12%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>STRENGTHS</div>
                  </div>
                  </div>
                  <div style={{width: '40%', height: '45px', position:'relative'}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                    
                    <img style={{width: '40px', top: '10%', height: '35px', left: '5%', position: 'absolute', transform: 'skew(45deg)'}} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '25%', left: '12%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>WEAKNESSES</div>
                  </div>
                </div>
                </div>
              <div style={{display: 'flex', flexDirection: 'row', top: '140px', position: 'relative', gap:'5%', height:'180px'}}>
                <div style={{width: '40%', left: '2%', position:'relative'}}>
                  
                  <div style={{ position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>
                    {data.strengths}
                  </div>
                </div>
                <div style={{width: '40%', left: '9%', position: 'relative'}}>
                  <div style={{ position: 'relative', width: '100%', color: '#464255', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 500, wordWrap: 'break-word'}}>
                    {data.weaknesses}
                  </div>
                </div>
              </div>
              
              

              
              <div style={{width: '100%', height: '20px', background: '#FBB13A',top:150,position: 'relative'}}>
                <div style={{color: '#464255', fontSize: '17px', textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700}}>© 2024 WPBL  *All data collected is based on the information provided by the players</div>
              </div>

            </TabPanel>
            <TabPanel>
              <div style={{width: '97%', left: '1%', height: '45px', position: 'relative'}}>
                <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}} />
                <img style={{width: '40px', top: '10%', height: '40px', left: '2%', position: 'absolute'}} src={ball} alt="ball" />
                <div style={{position: 'absolute', top: '30%', left: '5%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>TOP 5 TOURNAMENTS PLAYED</div>
              </div>
              <div style={{width:'94%', display:'flex', flexDirection:'row', gap:'6%', left:'1%', position:'relative'}}>            
                <div style={{ width:"46%",display:'flex', flexDirection:'column', position:'relative',gap:20, top:10}}>
                      <div style={{width: '100%', height: '20px',  textAlign:'center', position: 'relative'}}>
                        <span style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word'}}>
                          SINGLES
                        </span>
                      </div>
                      <div style={{width:'100%', display: 'flex',flexDirection: 'row', position: 'relative', gap:'1%'}}>
                        <div style={{width: '46%',height: '35px', position: 'relative'}}>
                          <div style={{width: '100%', display:'flex', justifyContent:'flex-start', height: '100%', background: '#FBB13A', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 35px) 100%, 0 100%)"}}>
                            <div style={{position: 'relative', top: '30%', left:'2%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Tournament</div>
                          </div>
                        </div>
                        <div style={{width: '50%',height: '35px', position: 'relative'}}>
                          <div style={{width: '100%', display:'flex', height: '100%', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                            <div style={{position: 'relative', top: '30%',color: '#ffffff', left:'10%', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Result</div>
                          </div>
                        </div>
                      </div>
                      <div style={{width:'100%', display: 'flex', flexDirection: 'column',position:'relative', gap:10}}>
                        {data.tournamentPerformanceSingls.length > 0 ?
                          data.tournamentPerformanceSingls.map((tournamentSingls,index) => (
                            tournamentSingls.tournament !=="0" && tournamentSingls.tournament !== 0 ?
                              <div style={{width:'100%', display: 'flex', flexDirection: 'row',position:'relative', gap:'6%'}} key={index}>
                                <div style={{width:'44%', color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentSingls.tournament}</div>
                                <div style={{width:'40%',color: '#464255', fontSize: '15px',textAlign:'left', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentSingls.result}</div>
                              </div>
                        :""
                          ))
                        :""}
                      </div>
                  </div>
                  <div style={{ width:"52%",display:'flex', flexDirection:'column', position:'relative',gap:20, top:10}}>
                    <div style={{width: '100%', textAlign:'center', height: '20px', position: 'relative'}}>
                      <span style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word'}}>
                          DOUBLES
                      </span>
                    </div>
                    <div style={{width:'100%', display: 'flex',flexDirection: 'row', position: 'relative', gap:'1%'}}>
                        <div style={{width: '46%',height: '35px', position: 'relative'}}>
                          <div style={{width: '100%', display:'flex', justifyContent:'flex-start', height: '100%', background: '#FBB13A', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 35px) 100%, 0 100%)"}}>
                            <div style={{position: 'relative', top: '30%', left:'2%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Tournament</div>
                          </div>
                        </div>
                    <div style={{width: '50%',height: '35px', position: 'relative'}}>
                      <div style={{width: '100%', height: '100%', display:'flex', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                       <div style={{position: 'relative', top: '30%', left:'10%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Result</div>
                      </div>
                    </div>
                  </div>
                  <div style={{width:'100%', display: 'flex', flexDirection: 'column',position:'relative', gap:10}}>
                      {data.tournamentPerformanceDoubles.length > 0 ?
                        data.tournamentPerformanceDoubles.map((tournamentDoubles,index) => (
                          tournamentDoubles.tournament !=="0" && tournamentDoubles.tournament !== 0 ?
                          <div style={{width:'100%', display: 'flex', flexDirection: 'row',position:'relative', gap:'6%'}} key={index}>
                            <div style={{width:'44%', color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentDoubles.tournament}</div>
                            <div style={{width:'40%',color: '#464255', fontSize: '15px',textAlign:'left', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentDoubles.result}</div>
                          </div>
                        :""
                        ))
                      :""}  
                  </div>
                </div>
            </div>
            <div style={{ width:"100%",display:'flex', flexDirection:'column', alignItems:'flex-start', position:'relative',gap:20, top:40, left:'1%'}}>
                    <div style={{width: '40%', textAlign:'center', height: '20px', position: 'relative'}}>
                    <span style={{color: '#464255', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word'}}>
                      MIXED DOUBLES
                    </span>
                  </div>
                  <div style={{width:'40%', display: 'flex',flexDirection: 'row', position: 'relative', gap:'1%'}}>
                        <div style={{width: '46%',height: '35px', position: 'relative'}}>
                          <div style={{width: '100%', display:'flex', justifyContent:'flex-start', height: '100%', background: '#FBB13A', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 35px) 100%, 0 100%)"}}>
                            <div style={{position: 'relative', top: '30%', left:'2%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>Tournament</div>
                          </div>
                        </div>
                    <div style={{width: '48%',height: '35px', position: 'relative'}}>
                      <div style={{width: '100%', height: '100%', display:'flex', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                       <div style={{position: 'relative', top: '30%', left:'10%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>Result</div>
                      </div>
                    </div>
                  </div>
                  <div style={{width:'50%', display: 'flex', flexDirection: 'column',position:'relative', gap:10}}>
                    {data.tournamentPerformanceMixedDoubles.length > 0 ?
                      data.tournamentPerformanceMixedDoubles.map((tournamentMixedDoubles,index) => (
                        tournamentMixedDoubles.tournament !=="0" && tournamentMixedDoubles.tournament !== 0 ?
                        <div style={{width:'100%', display: 'flex', flexDirection: 'row',position:'relative', gap:'6%'}} key={index}>
                          <div style={{width:'35%', color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentMixedDoubles.tournament}</div>
                          <div style={{width:'40%',color: '#464255', fontSize: '15px',textAlign:'left', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{tournamentMixedDoubles.result}</div>
                        </div>
                    :""
                      ))
                    :""}
                  </div>
                 
                  </div>
                   <div style={{width: '95%', left:'1%', top:80, height: '45px', position: 'relative'}}>
                    <div style={{width: '100%', height: '100%', background: '#CACAD3', borderRadius: '3px', clipPath: "polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0 100%)"}} />
                    <img style={{width: '40px', top: '15%', height: '35px', left: '1%', position: 'absolute'}} src={ball} alt="ball" />
                    <div style={{position: 'absolute', top: '25%', left: '4%', color: '#464255', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>MEDALS TALLY</div>
                    </div>  

            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', position:'relative', top:'100px', width:'100%', gap:'5%'}}>
            <div style={{display:'flex', flexDirection:'column', gap:'20px'}}>
            <div style={{display:'flex', flexDirection:'row', position:'relative', width: "55%", left: '25%', gap:'10px', alignItems:'center', }}>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={gold} />
                          <div style={{
                            color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                          }}>{data.goldsingle !=='' && data.goldsingle !== null ? data.goldsingle:0 }
                          </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={silver} />
                           <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.silversingle !=='' && data.silversingle !== null ? data.silversingle:0 }
                        </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={bronze} />
                          <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.bronzesingle !=='' && data.bronzesingle !== null ? data.bronzesingle:0 }
                        </div>
                      </div>
                  </div>
                  <div style={{
                            color: "#464255", fontSize: "18px",fontFamily: "Roboto",
                            fontWeight: 400, wordWrap: "break-word",textAlign:'center',
                            }}>SINGLES
                    </div>
                  </div>
                  
                  
                    <div style={{display:'flex', flexDirection:'column', gap:'20px'}}>
                    <div style={{display:'flex', flexDirection:'row', position:'relative', width: "55%", left: '25%', gap:'10px', alignItems:'center', }}>
                              <div style={{display:'flex', position:'relative'}}>
                                <img style={{
                            width: "100%"
                          }} src={gold} />
                          <div style={{
                            color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                          }}>{data.golddoubles !=='' && data.golddoubles !== null ? data.golddoubles:0 }
                          </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={silver} />
                           <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.silverdoubles !=='' && data.silverdoubles !== null ? data.silverdoubles:0 }
                        </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={bronze} />
                          <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.bronzedoubles !=='' && data.bronzedoubles !== null ? data.bronzedoubles:0 }
                        </div>
                      </div>
                  </div>
                  <div style={{
                            color: "#464255", fontSize: "18px",fontFamily: "Roboto",
                            fontWeight: 400, wordWrap: "break-word",textAlign:'center',
                            }}>DOUBLES
                    </div>
                  </div>
                  
                  
            <div style={{display:'flex', flexDirection:'column', gap:'20px'}}>
            <div style={{display:'flex', flexDirection:'row', position:'relative', width: "55%", left: '25%', gap:'10px', alignItems:'center', }}>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={gold} />
                          <div style={{
                            color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                          }}>{data.goldmixeddoubles !=='' && data.goldmixeddoubles !== null ? data.goldmixeddoubles:0 }
                          </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={silver} />
                           <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.silvermixeddoubles !=='' && data.silvermixeddoubles !== null ? data.silvermixeddoubles:0 }
                        </div>
                      </div>
                      <div style={{display:'flex', position:'relative'}}>
                        <img style={{
                            width: "100%"
                          }} src={bronze} />
                          <div style={{ 
                          color: "#464255", width:'100%', height:'100%', top:'21%', fontSize: "25px", display:'flex',fontFamily: "Roboto", fontWeight: 'bold', position:'absolute', alignItems:'center', justifyContent:'center',
                            wordWrap: "break-word"
                        }}>{data.bronzemixeddoubles !=='' && data.bronzemixeddoubles !== null ? data.bronzemixeddoubles:0 }
                        </div>
                      </div>
                  </div>
                  <div style={{
                            color: "#464255", fontSize: "18px",fontFamily: "Roboto",
                            fontWeight: 400, wordWrap: "break-word",textAlign:'center',
                            }}>MIXED DOUBLES
                    </div>
                  </div>
                 
                  </div>
                  
         <div style={{width: '100%', height: '20px', background: '#FBB13A',top:150,position: 'relative'}}>
          <div style={{color: '#464255', fontSize: '17px', textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700}}>© 2024 WPBL  *All data collected is based on the information provided by the players</div>
        </div>
      </TabPanel>
      <TabPanel>
        <div style={{width: (data.responseDataYouTube.length === 1 ? "40%" : "96%"), position: 'relative',overflow:"hidden", display:'table', left: (data.responseDataYouTube.length === 1 ? "30%" : "0%")}}>
            {data.responseDataYouTube.length > 0 ?
              data.responseDataYouTube.map((video,index) => (
                <ReactPlayer url={video} key={index} controls={true} style={{display:'table-cell',float:"left", float:(data.responseDataYouTube.length === 1 ? "unset" : "right"), marginRight:'2%', marginLeft:"0%", marginBottom:"2%"}}  width="45%" height="290px"/>
              ))
            : 
              <div style={{width: '100%',position: 'relative',textAlign: "center", fontSize: "40px",fontWeight: "600"}}>No Media and Videos</div>
          }
        </div>
    {/*    <div style={{width: (data.responseDataOther.length === 1 ? "40%" : "96%"), position: 'relative',overflow:"hidden", display:'table', left: (data.responseDataOther.length === 1 ? "30%" : "0%")}}>
            {data.responseDataOther.length > 0 ?
              data.responseDataOther.map((link,indexData) => (
                  <a href={link} className="addLink">
                    <iframe src={link} title="click to see" height="200" width="100%"></iframe>
                  </a>
              ))
            : 
              <div style={{width: '100%', height: '400px',position: 'relative',textAlign: "center", fontSize: "40px",fontWeight: "600"}}>No Media and Videos</div>
          }
        </div>*/}
        <div style={{width:'85%', display: 'flex',flexDirection: 'column',left:'5%',height:"100%", position: 'relative', gap:'2%' ,marginBottom: '10%'}}>
              <div style={{width: '100%', marginTop:'10px', marginBottom:'10px', position: 'relative'}}>
                <div style={{width: '100%', display:'flex', justifyContent:'flex-start', height: '40px', background: '#FBB13A', borderRadius: '3px', transform: 'skew(-45deg)'}}>
                  <div style={{position: 'relative', top: '30%', left:'2%', color: '#ffffff', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word', transform: 'skew(45deg)'}}>OTHER LINKS</div>
                </div>
              </div>
              <div style={{width:'100%', display: 'flex', position:'relative', left:'1%', flexDirection: 'column',position:'relative', gap:10}}>
              {data.responseDataOther.length > 0 ?
                data.responseDataOther.map((link,indexdata) => (
                    <div style={{width:'100%', display: 'flex', flexDirection: 'row',position:'relative', gap:'4%'}} key={indexdata}>
                    <a href={link}>
                      <div style={{width:'100%', color: '#464255', textAlign:'left', fontSize: '15px', fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word'}}>{link}</div>
                      </a>
                    </div>
                ))
              :
              <div style={{width: '100%',position: 'relative',textAlign: "center", fontSize: "40px",fontWeight: "600"}}>No Links</div>
            }
            </div>
        </div>
        <div style={{width: '100%', height: '20px', background: '#FBB13A',top:'10%', position: 'relative'}}>
          <div style={{color: '#464255', fontSize: '17px', textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700}}>© 2024 WPBL *All data collected is based on the information provided by the players</div>
        </div>
      </TabPanel>
      </Tabs>
      </div>

      
            </>

  :""}
</div>

    );
};  

export default PlayerDetails;