import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header/Header';

const Layout = () => {
  const location = useLocation();

  // Exclude Header from certain routes
  const hideHeaderOnPaths = ['/login','/mobile-not-supported'];
  const shouldShowHeader = !hideHeaderOnPaths.includes(location.pathname);

  return (
    <>
      {shouldShowHeader && <Header />}
      <Outlet /> {/* Render child routes here */}
    </>
  );
};

export default Layout;
